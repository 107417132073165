<template>
<v-container>
    <div>
      <navbar title="خانه" />
      <v-main class="pt-15">
          <location :address= "address" />
      </v-main>
    </div>
</v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import location from './components/cards/cardAddress'
import { mapState } from 'vuex'
import { viewAddressUser } from './models/address'
export default {
    props: ['locationID'],
    data: () => ({
        id: ''
    }),
   components:{
       navbar,
       location
   },
   computed: {
      ...mapState({
        address: state => state.address.viewAddress
      })
    },
    created () {
        viewAddressUser(this.$props.locationID)
    }
}
</script>